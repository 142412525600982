import api from "@/plugins/api";

export async function getTemplateId() {
  const { data } = await api.get(`user/client`);
  let template = data.template_id;
  return { template };
}
export async function getClientInfo(templateId) {
  const { data } = await api.get(`user/client/projects/${templateId}`);
  return { data };
}

export async function downloadFile(client, title) {
  const { data } =  await api.get(`flash/download/${client}/${title}`);
  return { data }
}

export async function getCertificate (reportId) {
  const { data } =  await api.get(
    `flash/report/${reportId}/true`
    );
  
  return { data }
}
