<template>
  <div class="column is-6-desktop" v-if="this.hidden == false">
    <div>
      <h5 class="has-text-left has-text-weight-semibold">{{ field.name }}</h5>
      <b-field>
        <b-select v-model="value" expanded :required="field.required || false">
          <option
            v-for="option in field.values"
            :value="option.value || option"
            :key="option.value || option"
            v-html="option.name || option"
          ></option>
        </b-select>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    field: Object,
  },
  methods: {
    ...mapMutations(["addAnswer"]),
  },
  data() {
    return {
      hidden: false,
    };
  },
  mounted() {
    if (this.client[0].clientTemplate.hidden) {
      for (const id of this.client[0].clientTemplate.hidden) {
        if (id == this.field.id.toString()) {
          this.hidden = true;
        }
      }
    }
    if (this.field.required || false) {
      this.addAnswer({ item: this.field.id, value: null, tagResponse: null });
    }
  },
  created() {
    this.client = this.$store.getters.getClient;
  },
  computed: {
    value: {
      get() {
        return null;
      },
      set(value) {
        this.addAnswer({ item: this.field.id, value, tagResponse: null });
      },
    },
  },
};
</script>

<style></style>
