<template>
  <div :class="{hidden: hidden}" :id="field.id" v-if="this.hidden == false">
    <div class="column is-6-desktop">
      <h5 class="has-text-left has-text-weight-semibold">{{ field.name }}</h5>
      <b-timepicker
        :required="field.required || false"
        :min-time="new Date(field.min)"
        :max-time="new Date(field.max)"
        v-model="timeValue"
        v-on:blur="setAnswer"
      >
      </b-timepicker>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    field: Object,
  },
  data() {
    return {
      timeValue: new Date(""),
      hidden: false,
    };
  },
  methods: {
    ...mapMutations(["addAnswer"]),
    setAnswer: function () {
      this.addAnswer({
        item: this.field.id,
        value: this.timeValue.toTimeString().slice(0, 5),
        tagResponse: null,
      });
    },
  },
  mounted() {
    if (this.client[0].clientTemplate.hidden) {
      for (const id of this.client[0].clientTemplate.hidden) {
        if (id == this.field.id.toString()) {
          this.hidden = true;
        }
      }
    }
    if (this.field.required || false) {
      this.addAnswer({ item: this.field.id, value: null, tagResponse: null });
    }
  },
  created() {
    this.client = this.$store.getters.getClient;
  },
};
</script>

<style>
</style>