<template>
  <div class="m-3 columns is-multiline">
    <template v-for="(field, fieldId) in filterHidden">
      <radioField
        v-if="field.expanded && field.type !== 'multichoice'"
        :field="field"
        :key="fieldId"
      >
      </radioField>
      <component
        v-if="!field.expanded || field.type === 'multichoice'"
        :is="`${field.type}Field`"
        :field="field"
        :key="fieldId"
      >
      </component>
    </template>
  </div>
</template>

<script>
import listField from "@/components/Fields/SelectField";
import userListField from "@/components/Fields/UserListField";
import radioField from "@/components/Fields/RadioField";
import multichoiceField from "@/components/Fields/CheckBoxField";
import imageField from "@/components/Fields/ImageField";
import fileField from "@/components/Fields/FileField";
import stringField from "@/components/Fields/StringField";
import textField from "@/components/Fields/TextField";
import signatureField from "@/components/Fields/SignatureField";
import dateField from "@/components/Fields/DateField";
import timeField from "@/components/Fields/TimeField";

export default {
  props: {
    fields: Array,
    componentName: { type: String },
  },
  components: {
    listField,
    userListField,
    radioField,
    multichoiceField,
    imageField,
    fileField,
    stringField,
    textField,
    signatureField,
    dateField,
    timeField,
  },
  computed: {
    filterHidden() {
      return this.fields.filter((field) => (field.hidden || false) === false);
    },
  },
};
</script>

<style>
</style>