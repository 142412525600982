<template>
  <div class="column is-left" v-if="this.hidden == false">
    <h5 class="has-text-left has-text-weight-semibold">{{ field.name }}</h5>
    <div rounded>
      <div
        class="column is-12-desktop has-text-centered box"
        style="border: 1px #dbdbdb solid"
      >
        <VueSignaturePad
          width="100%"
          height="300px"
          ref="signaturePad"
          :options="{ onEnd }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad);
import { mapMutations } from "vuex";

export default {
  props: {
    text: String,
    name: String,
    parameters: Object,
    field: Object,
  },
  data() {
    return {
      hidden: false,
    };
  },
  methods: {
    onEnd() {
      const { data, isEmpty } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.addAnswer({
          item: this.field.id,
          value: data,
          tagResponse: null,
          isSignature: true,
        });
      }
    },
    ...mapMutations(["addAnswer"]),
  },
  created() {
    this.client = this.$store.getters.getClient;
  },
  mounted() {
    if (this.client[0].clientTemplate.hidden) {
      for (const id of this.client[0].clientTemplate.hidden) {
        this.test = this.field.id.toString();
        this.test2 = id;
        if (id == this.field.id.toString()) {
          this.hidden = true;
        }
      }
    }
    if (this.field.required || false) {
      this.addAnswer({ item: this.field.id, value: null, tagResponse: null });
    }
  },
};
</script>

<style>
</style>