<template>
  <div class="box has-background-grey-lighter p-2">
    <h3 class="box has-background-white has-text-weight-semibold has-text-left">
      {{ sectionName }}
    </h3>
    <h3
      v-if="commentBoxTop !== null"
      class="box has-background-white has-text-weight-semibold has-text-left is-size-4-desktop"
    >
      {{ this.commentBoxTop }}
    </h3>
    <div
      class="card"
      v-for="(valuesComp, index) in fieldGroups"
      :key="index"
      :fields="valuesComp.fields"
    >
      <h3
        class="has-background-info-light is-family-sans-serif box has-text-left has-text-weight-semibold"
      >
        {{ sectionNumber }}. {{ title }}
      </h3>
      <group-component
        v-for="(valuesC, indexC) in fieldGroups"
        :key="indexC"
        :fields="valuesC.fields"
      ></group-component>
    </div>
  </div>
</template>

<script>
import groupComponent from "@/components/GroupComponent";

export default {
  props: {
    title: String,
    fieldGroups: Array,
    sectionNumber: Number,
  },
  data() {
    return {
      client: {},
      sectionName: this.title,
      commentBoxTop: null,
    };
  },
  components: {
    groupComponent,
  },
  methods: {
    getSectionName() {
      if (
        this.client[0].clientTemplate != 0 &&
        this.client[0].clientTemplate.template_name != null
      ) {
        this.sectionName = this.client[0].clientTemplate.template_name;
      }
    },
    showCommentBox() {
      if (
        this.client[0].clientTemplate != 0 &&
        this.client[0].clientTemplate.comment_box_top != null
      ) {
        this.commentBoxTop = this.client[0].clientTemplate.comment_box_top;
      }
    },
  },
  created() {
    this.client = this.$store.getters.getClient;
    this.getSectionName();
    this.showCommentBox();
  },
};
</script>

<style></style>
