import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import store from './store'
import Axios from 'axios';
import './registerServiceWorker'

Vue.use(Buefy)

Vue.config.productionTip = false

if (localStorage.getItem('token')) {
  Vue.prototype.$http = Axios;
  Vue.prototype.$http.defaults.headers.common['Authorization'] = localStorage.getItem('token');
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
