<template>
  <section class="column is-6-desktop" v-if="this.hidden == false">
    <div>
      <h5 class="has-text-left has-text-weight-semibold">{{ field.name }}</h5>
      <b-field>
        <b-upload
          v-model="dropFiles"
          @input="updateFields()"
          multiple
          expanded
          class="is-hidden-desktop"
        >
          <!-- Mobile -->
          <a class="button is-info is-fullwidth">
            <b-icon icon="upload"></b-icon>
            <p>Evidencia</p>
          </a>
          <!-- Desktop -->
        </b-upload>
        <b-upload
          v-model="dropFiles"
          @input="updateFields()"
          multiple
          expanded
          drag-drop
          class="is-hidden-touch"
        >
          <div class="m-5">
            <p>
              <a class="button is-info" style="float: left">
                Cargar un archivo
              </a>
            </p>
            <br />
            <br />
            <h4>Arrastra los archivos aquí</h4>
          </div>
        </b-upload>
      </b-field>
      <div class="tags">
        <span
          v-for="(file, index) in dropFiles"
          :key="index"
          class="tag is-info is-light"
        >
          {{ file.name }}
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile(index)"
          ></button>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    field: Object,
  },
  data() {
    return {
      dropFiles: [],
      files: [],
      hidden: false,
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
      let files = Object.values(this.dropFiles).map((e) => e.name);
      this.addAnswer({
        item: this.field.id,
        value: files,
        tagResponse: null,
        hasImage: true,
      });
    },
    ...mapMutations(["addAnswer", "setImages"]),
    updateFields() {
      let files = Object.values(this.dropFiles).map((e) => e.name);
      this.addAnswer({
        item: this.field.id,
        value: files,
        tagResponse: null,
        hasImage: true,
      });
      this.setImages({ item: this.field.id, images: this.dropFiles });
    },
  },
  mounted() {
    if (this.client[0].clientTemplate.hidden) {
      for (const id of this.client[0].clientTemplate.hidden) {
        if (id == this.field.id.toString()) {
          this.hidden = true;
        }
      }
    }
    if (this.field.required || false) {
      this.addAnswer({
        item: this.field.id,
        value: null,
        tagResponse: null,
        hasImage: true,
      });
    }
  },
  created() {
    this.client = this.$store.getters.getClient;
  },
};
</script>