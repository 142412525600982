<template>
  <div class="column is-6-desktop" v-if="this.hidden == false">
    <div>
      <h5 class="has-text-left has-text-weight-semibold">{{ field.name }}</h5>
      <b-field >
        <b-select v-model="value" expanded  :required="field.required || false">
          <option
            v-for="option in userList"
            :value="option.value || option"
            :key="option.value || option"
          >
            {{ option.name || option }}
          </option>
        </b-select>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    field: Object,
  },
  methods: {
    ...mapMutations(["addAnswer"]),
  },
  data() {
    return {
      responsibles: [],
      project: [],
      hidden: false,
    };
  },
  mounted() {
    if (this.client[0].clientTemplate.hidden) {
      for (const id of this.client[0].clientTemplate.hidden) {
        if (id == this.field.id.toString()) {
          this.hidden = true;
        }
      }
    }
    if (this.field.required || false) {
      this.addAnswer({ item: this.field.id, value: null, tagResponse: null });
    }
  },
  created() {
    this.client = this.$store.getters.getClient;
    this.responsibles = this.$store.getters.getResponsibles;
  },
  computed: {
    value: {
      get() {
        return null;
      },
      set(value) {
        this.addAnswer({ item: this.field.id, value, tagResponse: null });
      },
    },
    userList(){
      let project = this.$store.getters.getProject;
      if (project.length == 0){
        return [];
      } 
      let users = [];
      for(let projectVal in this.responsibles[0].projects){
        if(project[0].id == projectVal){
          users = this.responsibles[0].projects[projectVal];
        }
      }
      return users;
    }
  },
};
</script>

<style>
</style>