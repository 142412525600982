<template>
  <div>
    <h2
      class="has-background-white has-text-weight-semibold box is-family-sans-serif is-size-4-desktop is-size-5-mobile"
      v-if="this.hasProjectList == false"
    >
      {{ title }}
    </h2>
    <field-component
      v-for="(subsection, index) in subsections"
      :key="index"
      :sectionNumber="index + 1"
      :title="subsection.name"
      :fieldGroups="subsection.groups"
    ></field-component>
    <h3
      v-if="commentBoxBottom !== null"
      class="box has-background-white has-text-weight-semibold has-text-left is-size-4-desktop"
    >
      {{ this.commentBoxBottom }}
    </h3>
  </div>
</template>

<script>
import fieldComponent from "@/components/FieldComponent";

export default {
  props: {
    title: String,
    subsections: Array,
  },
  components: {
    fieldComponent,
  },
  data() {
    return {
      hasProjectList: false,
      commentBoxBottom: null,
    };
  },
  methods: {
    hideTitle() {
      if (
        this.client[0].clientTemplate != 0 &&
        this.client[0].clientTemplate.project_selector == true
      ) {
        this.hasProjectList = true;
      }
    },
    showCommentBox() {
      if (
        this.client[0].clientTemplate != 0 &&
        this.client[0].clientTemplate.comment_box_bottom != null
      ) {
        this.commentBoxBottom =
          this.client[0].clientTemplate.comment_box_bottom;
      }
    },
  },
  created() {
    this.client = this.$store.getters.getClient;
    this.hideTitle();
    this.showCommentBox();
  },
};
</script>

<style></style>
