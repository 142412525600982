import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

api.interceptors.request.use((config) => {

  let token = localStorage.getItem('token');
  if (token) {
    config.headers = {
      Authorization: token,
    }
  }

  return config;
});

export default api;