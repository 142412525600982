import Vue from "vue";
import VueRouter from "vue-router";
import FormularioView from "../views/FormularioView.vue";
import api from "@/plugins/api";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Formulario",
    component: FormularioView,
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ErrorView.vue"),
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccessView.vue')
  },
  {
    path: "/dorothy/:magic",
    name: "dorothy",
    beforeEnter: async (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next({ name: "Formulario" });
        return;
      }
      try {
        const { data } = await api.get(`covid/oz/${to.params.magic}`);
        if (data.token) {
          store.dispatch("setToken", data.token);
          next({ name: "Formulario" });
          return;
        }
      } catch (e) {
        next({ name: "Error" });
      }
      next({ name: "Error" });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
