<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <div v-if="clientData.logo !== null">
          <img
            :src="clientData.logo"
            class="is-size-2-desktop is-size-5-mobile"
          />
        </div>
        <div v-if="clientData.logo === null">
          <h2
            class="has-background-white has-text-weight-semibold is-family-sans-serif is-size-4-desktop is-size-5-mobile"
          >
            {{ clientData.name.toUpperCase() }}
          </h2>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
import { mapMutations } from "vuex";
import { getClientInfo, getTemplateId } from "@/plugins/form.js";

export default {
  data() {
    return {
      clientData: {},
    };
  },
  components: {},
  methods: {
    ...mapMutations(["setClient"]),
    async getClient() {
      try {
        const { template } = await getTemplateId();
        const { data } = await getClientInfo(template);
        this.setClientValues(data);
      } catch (error) {
        this.errorAlert(
          "Error",
          "Ha ocurrido un error al obtener la información del cliente"
        );
      }
    },
    setClientValues(data) {
      this.clientData = data.client;
      this.setClient({
        client: data.client,
        clientTemplate: data.client_template,
      });
    },
    errorAlert(header, text) {
      this.$buefy.dialog.alert({
        title: header,
        message: text,
        type: "is-danger",
      });
    },
  },
  mounted() {
    this.getClient();
  },
};
</script>
