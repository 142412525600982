<template>
  <div class="Formulario has-background-info-light">
    <navbar />
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="false"
    >
    </b-loading>
    <section class="hero">
      <h3
        v-if="title !== null"
        class="box has-background-white has-text-weight-semibold has-text-left is-size-4-desktop"
      >
        {{ title }}
      </h3>
      <h3
        v-if="title === null"
        class="box has-background-white has-text-weight-semibold has-text-left is-size-4-desktop"
      >
        {{ responseData.data.title }}
      </h3>
      <div class="hero-body">
        <div
          class="container has-text-centered"
          v-if="this.hasProjectList == true"
        >
          <h5 class="has-text-left has-text-weight-semibold">
            {{ this.projectSelectorTitle || "Seleccione un Centro de Trabajo" }}
          </h5>
          <b-select
            v-model="selectedProject"
            expanded
            :required="'true'"
            @input="getProjects()"
          >
            <option
              v-for="option in projectList"
              :value="option"
              :key="option.id || option"
            >
              {{ option.name || option }}
            </option>
          </b-select>
        </div>
        <br />
        <div class="container has-text-centered">
          <section-component
            v-for="(section, index) in responseData.data.sections"
            :key="index"
            :title="projectTitle"
            :subsections="section.subsections"
          ></section-component>
        </div>
        <div class="columns buttons is-centered mt-5">
          <div class="column is-one-quarter-tablet">
            <b-button
              class="button is-info is-medium is-fullwidth"
              :disabled="reportSended"
              @click="sendReportButton()"
            >
              <strong>Enviar</strong>
            </b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/plugins/api";
import sectionComponent from "@/components/SectionComponent";
import navbar from "@/components/NavbarComponent";
import { uploadSignature } from "@/plugins/signature_service";
import { mapMutations } from "vuex";
import { getClientInfo, getTemplateId } from "@/plugins/form.js";

export default {
  data() {
    return {
      isLoading: false,
      isFullPage: true,
      responseData: { data: { sections: [] } },
      title: "",
      projectSelectorTitle: "",
      reportResponse: {},
      reportSended: false,
      hasProjectList: false,
      projectTitle: "",
      projectId: "",
      templateId: "",
      selectedProject: [],
      client: {},
      projectList: {},
    };
  },
  components: {
    sectionComponent,
    navbar,
  },
  methods: {
    ...mapMutations(["setProject", "setResponsibles", "setReport"]),
    async getProjectList() {
      try {
        const { template } = await getTemplateId();
        const { data } = await getClientInfo(template);
        this.setGeneralValues(data);
      } catch (error) {
        this.errorAlert(
          "Error",
          "Ha ocurrido un error al obtener la información del cliente"
        );
        this.isLoading = false;
      }
    },
    async getProjects() {
      try {
        let foundProject = false;
        let dataProject = this.projectList;
        if (this.selectedProject.length !== 0) {
          this.setProject({
            id: this.selectedProject.id,
            name: this.selectedProject.name,
          });
          dataProject = [this.selectedProject];
        }
        for (const project of dataProject) {
          const { data } = await api.get(`projects/${project.id}/templates`);
          if (this.hasProjectList === false) {
            this.setProject({ id: project.id, name: project.name });
          }
          this.projectId = project.id;
          this.projectTitle = project.name;
          foundProject = true;
          this.templateId = data.records[0].id;
          this.title = this.title == "" ? data.records[0].title : this.title;
          if (foundProject == true) {
            break;
          }
        }
        this.getForm(this.projectId);
      } catch (error) {
        this.errorAlert("Error", "Ha ocurrido un error");
        this.isLoading = false;
      }
    },
    async getForm(projectId) {
      try {
        const { data } = await api.get(
          `projects/${projectId}/templates/${this.templateId}`
        );
        this.responseData = data;
        this.isLoading = false;
      } catch (error) {
        this.errorAlert("Error", "Ha ocurrido un error");
        this.isLoading = false;
      }
    },
    async sendImage() {
      // Para enviar imagenes
      let fieldsWithFiles = this.$store.getters.getImages;
      let fieldsWithSignature = this.$store.getters.getSignatures;
      let cont = 0;
      this.reportSended = true;
      this.isLoading = true;

      // Subiendo las firmas
      for (const field of fieldsWithSignature) {
        await uploadSignature(field);
      }
      
      let data = this.$store.getters.allAnswers;
     
      fieldsWithFiles.forEach(async (files) => {
        files.forEach(async (file) => {
          const fd = new FormData();
          fd.append(
            "name",
            `${Date.now()}_${Math.floor(Math.random() * 1000)}_${file.name}`
          );
          fd.append("file", file);
          try {
            let response = await api.post("upload/image", fd);
            let reportResponse = data.map(function (rr) {
              if (rr.hasImage) {
                rr.value[cont] = response.data.filePath;
                cont++;
              }
              return rr;
            });
            if ((await reportResponse) && cont == fieldsWithFiles[0].length) {
              this.sendData(reportResponse);
            }
          } catch (error) {
            this.errorAlert("Error", "Ha ocurrido un error");
            this.reportSended = false;
            this.isLoading = false;
          }
        });
      });
      if (fieldsWithFiles == 0){
        this.sendData(data);
      }
    },
    async sendData(reportResult) {
      //Para enviar la respuesta del reporte
      try {
        let project = this.$store.getters.getProject;
        const { data } = await api.post(
          `projects/${project[0].id}/templates/${this.templateId}/reports`,
          {
            fields: reportResult,
            sent: 1,
          }
        );
        this.setReport({id: data.id});
        this.$router.push("/Success");
        this.setProject({});
        this.isLoading = false;
      } catch (error) {
        this.errorAlert("Error", "Ha ocurrido un error");
        this.reportSended = false;
        this.isLoading = false;
      }
    },
    errorAlert(header, text) {
      this.$buefy.dialog.alert({
        title: header,
        message: text,
        type: "is-danger",
      });
    },
    setGeneralValues(data) {
      let responsibles = data.responsibles;
      if (responsibles) {
        this.setResponsibles({ projects: responsibles });
      }
      this.projectList = data.projects;
      if (
        data.clientTemplate != 0 &&
        data.client_template.project_selector == true
      ) {
        this.hasProjectList = data.client_template.project_selector;
      }
      if (
        data.clientTemplate != 0 &&
        data.client_template.template_name != null
      ) {
        this.title = data.client_template.template_name;
      }
      if (
        data.clientTemplate != 0 &&
        data.client_template.project_selector_title != null
      ) {
        this.projectSelectorTitle = data.client_template.project_selector_title;
      }
      this.getProjects();
      this.isLoading = false;
    },
    sendReportButton() {
      let sendReport = true;
      if (
        this.hasProjectList == true &&
        (this.selectedProject.length == 0 || this.selectedProject == null)
      ) {
        sendReport = false;
        this.errorAlert("Datos", "Debe seleccionar un Centro de Trabajo");
      }
      if (this.selectedProject.length !== 0) {
        this.setProject({
          id: this.selectedProject.id,
          name: this.selectedProject.name,
        });
      }
      
      if (this.$store.getters.isFilled && sendReport == true) {
        this.sendImage();
      } else {
        this.errorAlert("Datos", "Faltan datos por llenar");
      }
    },
  },
  mounted() {
    this.getProjectList();
    this.isLoading = true;
  },
  computed: {
    canSend() {
      return this.$store.getters.isFilled;
    },
  },
};
</script>
