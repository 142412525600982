<template>
  <section class="column buttons is-6-desktop" v-if="this.hidden == false">
    <h5 class="has-text-left has-text-weight-semibold">{{ field.name }}</h5>
      <div class="column is-half">
        <b-button 
          class="button is-info is-medium is-fullwidth"
          @click="download"
        >
        Descargar
        </b-button>
      </div>
  </section>
</template>

<script>
import { downloadFile } from "@/plugins/form.js";

export default {
  props: {
    field: Object,
  },
  data() {
    return {
      files: [],
      hidden: false,
    };
  },
  methods: {
    async download() {
      try {
        const { data } = await downloadFile(this.clientData.name.toLowerCase(), this.field.title || "");
        window.location.href = data.url;
      } catch (error) {
        this.errorAlert(
          "Error",
          "Ha ocurrido un error al obtener la información del tríptico"
        );
      }
    },
    errorAlert(header, text) {
      this.$buefy.dialog.alert({
        title: header,
        message: text,
        type: "is-danger",
      });
    },
  },
  created() {
      let data = this.$store.getters.getClient;
      this.clientData = data[0].client;
    }
};
</script>