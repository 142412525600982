<template>
  <div class="column is-6-desktop" v-if="this.hidden == false">
    <div>
      <h5 class="has-text-left has-text-weight-semibold">{{ field.name }}</h5>
      <b-field >
          <b-input 
          type="textarea"
          :required="field.required || false"
          v-model="textValue"
          v-on:blur="setAnswer"></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>

import { mapMutations } from 'vuex'

export default {
  props: {
     field: Object
  },
  data() {
    return {
      textValue: '',
      hidden: false
    }
  },
  mounted() {
    if (this.client[0].clientTemplate.hidden){
      for (const id of this.client[0].clientTemplate.hidden) {
        if (id == this.field.id.toString()){
          this.hidden = true;
        }
      }
    }
    if(this.field.required || false ) {
      this.addAnswer({item:this.field.id, value: null, tagResponse: null})
    }
  },
  methods:{
    ...mapMutations(['addAnswer']),
    setAnswer: function () {
        this.addAnswer({item:this.field.id,value: this.textValue, tagResponse: null})
    }
  },
  created() {
    this.client = this.$store.getters.getClient; 
  }
}
</script>

<style>

</style>