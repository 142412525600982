<template>
  <div class="column is-6-desktop" v-if="this.hidden == false">
    <h5 class="has-text-left has-text-weight-semibold">{{ field.name }}</h5>
    <b-field>
      <section v-for="option in field.values" :key="option.value || option">
        <!-- <div >  -->
        <b-radio
          v-model="value"
          name="name"
          :required="field.required || false"
          :native-value="option.value || option"
        >
          <span>{{ option.name || option }}</span>
        </b-radio>
        <!-- </div>  -->
      </section>
    </b-field>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    field: Object,
  },
  data() {
    return {
      hidden: false,
    };
  },
  methods: {
    ...mapMutations(["addAnswer"]),
  },
  created() {
    this.client = this.$store.getters.getClient;
  },
  mounted() {
    if (this.client[0].clientTemplate.hidden) {
      for (const id of this.client[0].clientTemplate.hidden) {
        if (id == this.field.id.toString()) {
          this.hidden = true;
        }
      }
    }
    if (this.field.required || false) {
      this.addAnswer({ item: this.field.id, value: null, tagResponse: null });
    }
  },
  computed: {
    value: {
      get() {
        return null;
      },
      set(value) {
        this.addAnswer({ item: this.field.id, value, tagResponse: null });
      },
    },
  },
};
</script>

<style>
</style>