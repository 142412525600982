import api from '@/plugins/api';
import store from '@/store/index';

export async function uploadSignature({ value, ...values }) {
    // transformación de la imagen
    const file = dataURLtoFile(value, `signature_${Date.now()}_${Math.floor(Math.random() * 1000)}.png`);
    const fd = new FormData();
    fd.append("name", file.name);
    fd.append("file", file);
    // Esperamos la subida y rescribimos el campo
    let { data: { filePath } } = await api.post("upload/image", fd);
    values['value'] = [filePath];

    // lo modificamos en el store, aprovechando es item-indexed
    return await store.commit('addAnswer', values);
}

/**
 * Funcion helper de transformacion de data a archivo...
 * @param {*} dataurl
 * @param {*} filename
 * @returns File
 */
const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
}